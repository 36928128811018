import React from "react";
import { css } from "@emotion/react";

import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const teamName = css`
  box-shadow: none;
  display: block;
  text-align: center;
  &:hover {
    text-decoration: underline;
  }
`;

export default function TeamCover({ team }) {
  const teamUrl = `/teams/${team.fields.slug}`;
  return (
    <div>
      <Link to={teamUrl} title={team.name}>
        <GatsbyImage image={getImage(team.images[0])} alt={team.name} />
      </Link>
      <Link to={teamUrl} css={teamName} title={team.name}>
        {team.shortName}
      </Link>
    </div>
  );
}

export const teamCoverFragment = graphql`
  fragment Team_cover on TeamsJson {
    id
    name
    shortName
    fields {
      slug
    }
    images {
      childImageSharp {
        gatsbyImageData(width: 50, placeholder: BLURRED)
      }
    }
  }
`;
