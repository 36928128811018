import React from "react";

import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactAudioPlayer from "react-audio-player";
import Layout from "../components/layout";
import SEO from "../components/seo";
import TeamCover from "../components/TeamCover";
import { rhythm, scale } from "../utils/typography";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const { previousEpisode, nextEpisode } = post;
    const teams = post.frontmatter.teams;
    const featuredImgFluid = post.frontmatter.featuredImage
      ? post.frontmatter.featuredImage.childImageSharp.gatsbyImageData
      : null;

    return (
      <Layout>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          featuredImage={post.frontmatter.featuredImage}
        />
        <article>
          <header>
            <h1
              style={{
                marginTop: rhythm(1),
                marginBottom: 0,
              }}
            >
              {post.frontmatter.title}
            </h1>

            <time
              dateTime={post.frontmatter.fullDate}
              style={{
                ...scale(-1 / 5),
                display: `block`,
                marginBottom: rhythm(1),
              }}
            >
              {post.frontmatter.date}
            </time>
          </header>

          {featuredImgFluid && (
            <section aria-label="Cover Art">
              <GatsbyImage image={featuredImgFluid} alt={post.frontmatter.altText} loading="eager" />
            </section>
          )}

          {teams && (
            <section aria-label="Teams" style={{ display: "flex", justifyContent: "flex-start" }}>
              <b style={{ margin: "10px" }}>Teams:</b>
              <ul style={{ listStyle: "none", display: "inline-flex" }}>
                {teams.map((team) => (
                  <li key={team.id} style={{ margin: "10px" }}>
                    <TeamCover team={team} />
                  </li>
                ))}
              </ul>
            </section>
          )}

          <section>
            <ReactAudioPlayer src={post.frontmatter.enclosure.url} autoPlay={false} title="Podcast Audio" controls />
          </section>
          <section aria-label="Description" dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
        </article>

        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previousEpisode && (
                <Link to={previousEpisode.fields.slug} rel="prev">
                  ← Previous Episode
                </Link>
              )}
            </li>
            <li>
              {nextEpisode && (
                <Link to={nextEpisode.fields.slug} rel="next">
                  Next Episode →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query AudioPostBySlug($fields__slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $fields__slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        fullDate: date
        description
        featuredImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
        altText
        enclosure {
          url
        }
        teams {
          ...Team_cover
        }
      }
      nextEpisode {
        frontmatter {
          title
        }
        fields {
          slug
        }
      }
      previousEpisode {
        frontmatter {
          title
        }
        fields {
          slug
        }
      }
    }
  }
`;
